@font-face {
  font-family: FontAwesome;
  src: local("MyFont"), url(../font/fa-light-300.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: FontAwesome;
  src: local("MyFont"), url(../font/fa-brands-400.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(../font/FCIconic-Light.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(../font/FCIconic-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(../font/FCIconic-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(../font/FCIconic-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: FREE3OF9_NEW;
  src: local("MyFont"), url(../font/3OF9_NEW.TTF) format("truetype");
  font-weight: bold;
}

input,

select,

textarea,

.btn,

a,

body,

span,

label,

div {
  font-size: 0.85rem !important;
}

.swal2-icon-content {
  font-size: 3.75em !important;
}

body {
  font-family: FCIconic;
  src: local("MyFont"), url(../font/FCIconic-Regular.ttf) format("truetype");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px !important;
  height: 100%;
  background-color: #efeff5 !important;
}

.background-img {
  background-image: url("../images/bglogin.png") !important;
  background-color: #2c278868 !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.swal2-icon-content {
  font-size: 3.75em !important;
}

html {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.card {
  border-radius: 16px;
  border: 0;
}

.center {
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer !important;
}

.border-dash {
  border: 2px dashed #b3b3b3;
}

th {
  font-weight: normal !important;
}

.w-33 {
  width: 33% !important;
}

.w-25 {
  width: 25% !important;
}



/* CSS LOGO */
.logo {
  width: 50%;
  object-fit: contain;
}

.logo-menu {
  width: 148px;
}


/* CSS SIDEBAR */
.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding-left: 40px !important;
}

.pro-menu-item.active {
  background-color: #4E6FFB;
  color: #232f85;
}

.menu-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #a8a8a8;
}

.pro-sidebar-layout {
  background-color: #0F1A35 !important;
  position: fixed !important;
}

.pro-icon {
  background-color: #0000 !important;
  color: #ffffff !important;
}

.pro-button {
  color: #ffffff !important;
}

.pro-inner-item {
  height: 56px !important;
}

.pro-inner-item:hover {
  background-color: #a8a8a8 !important;
  color: #a8a8a8 !important;
}

.pro-item-content {
  color: #ffffff !important;
}

.pro-arrow {
  color: #333333 !important;
}

.pro-inner-list-item {
  background-color: #0F1A35 !important;
  color: #333333 !important;
}

.pro-sidebar {
  height: 100% !important;
  width: 260px !important;
  min-width: 260px !important;


}

.pro-sidebar-layout {
  background-color: #0F1A35 !important;
  color: #5c6873;
  width: 260px;
}

.pro-inner-item:active {
  background-color: #4E6FFB !important;
  color: #ffffff;
}

.pro-inner-item:active span {

  color: #ffffff;
}

.pro-inner-item:active .pro-arrow {
  color: #ffffff;
}

.pro-inner-item:hover {
  background-color: #4E6FFB !important;
  color: #ffffff;
}

.pro-inner-item:hover .pro-icon-wrapper {
  background-color: #4E6FFB !important;
  color: #ffffff;
}

.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  background-color: #4E6FFB !important;
  color: #ffffff;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: #00000000;
}

.pro-inner-item:hover span {
  background-color: #4E6FFB !important;
  color: #ffffff;
}

.pro-inner-item:hover .pro-arrow {
  color: #ffffff;
}

.pro-inner-list-item {
  background-color: #ffffff;

}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: #ffffff;
  color: #5c6873;
}

.pro-inner-list-item:hover {
  background-color: #ffffff;
  color: #ffffff;
}

.pro-inner-list-item:active {
  background-color: #ffffff;
  color: #ffffff;
}

.pro-icon {
  background-color: #ffffff;
  color: #5c6873;
}

.pro-icon:hover {
  background-color: #ffffff;
  color: #ffffff;
}

.pro-icon:active {
  background-color: #ffffff;
  color: #ffffff;
}

/* CSS ICON */
.barcode {
  font-family: FREE3OF9_NEW;
  src: local("MyFont"), url(../font/3OF9_NEW.TTF) format("truetype");
}

.icon {
  font-family: FontAwesome;
  src: local("MyFont"), url(../font/fa-light-300.woff) format("woff");
  font-size: 16px;
  padding-top: 2px;
  height: 20px;
}

.icon-brand {
  font-family: FontAwesome;
  src: local("MyFont"), url(../font/fa-brands-400.woff) format("woff");
  /* font-size: 18px; */
  height: 24px;
}

/* CSS INPUT */
.w3-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: FontAwesome;
  src: local("MyFont"), url(../font/fa-light-300.woff) format("woff");
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 6px;
  color: #999;
}

input[type="checkbox"],
input[type="radio"] {
  filter: hue-rotate(250deg);
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #bd0392;
}

input[type="search"] {
  padding-left: 36px;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTExIDhDMTAuNjA2IDggMTAuMjE1OSA4LjA3NzYgOS44NTE5NSA4LjIyODM2QzkuNDg3OTcgOC4zNzkxMyA5LjE1NzI2IDguNjAwMSA4Ljg3ODY4IDguODc4NjhDOC42MDAxIDkuMTU3MjYgOC4zNzkxMyA5LjQ4Nzk3IDguMjI4MzYgOS44NTE5NUM4LjA3NzYgMTAuMjE1OSA4IDEwLjYwNiA4IDExIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMCAyMEwxNyAxNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 8px;
  background-color: #ffffff;
  width: 100%;
}

.search {
  padding-left: 36px;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTExIDhDMTAuNjA2IDggMTAuMjE1OSA4LjA3NzYgOS44NTE5NSA4LjIyODM2QzkuNDg3OTcgOC4zNzkxMyA5LjE1NzI2IDguNjAwMSA4Ljg3ODY4IDguODc4NjhDOC42MDAxIDkuMTU3MjYgOC4zNzkxMyA5LjQ4Nzk3IDguMjI4MzYgOS44NTE5NUM4LjA3NzYgMTAuMjE1OSA4IDEwLjYwNiA4IDExIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMCAyMEwxNyAxNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 8px;
  background-color: #ffffff;
  width: 100%;
}

.form-control {
  height: 44px;
  padding-top: 0 !important;
  background-color: #F5F5F5 !important;
}

.form-control-search {
  height: 44px;
  border: 0px;
  padding-top: 3px !important;
  background-color: #FFFFFF !important;
  border-radius: 8px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eaeaea !important;
}

.form-control-select {
  display: block;
  width: 100%;
  height: 44px;
  border: 0px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #FFFFFF !important;
  background-clip: padding-box;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-inputfile {
  width: 100%;
  height: 44px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background-color: #FFFFFF !important;
  background-clip: padding-box;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-style: solid;
  border-width: 2px;
  border-color: #4E6FFB !important;
  text-align: center;
  display: inline-block;
}

select {
  -webkit-appearance: none;
}

.css-1s2u09g-control {
  border: 0px !important;
  background-color: transparent !important;
  min-height: unset !important;
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eaeaea !important;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

/* CSS BUTTON */
.btn {
  border-radius: 10px;
  height: 44px;
}

.btn-sm {
  border-radius: 10px;
  height: 30px;
}

.btn-circle {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-primary {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #4E6FFB;
  color: #ffffff;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-white {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: transparent;
  color: #333333;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-white {
  background-color: #ffffff !important;
  color: #333333;
}

/* CSS DROPDOWN */
.dropdowns {
  position: relative;
  display: inline-block;
  align-items: flex-end;
  z-index: 1;
}

.dropdowns-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdowns-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:active {
  background-color: #ddd;
}

.dropdowns:hover .dropdowns-content {
  display: block;
}

.dropdowns:hover .dropbtn {
  background-color: #ddd;
}

/* CSS BACKGROUND */
.status-primary {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f5597;
  color: #ffffff;
}

.status-secondary {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c757d;
  color: #f8f9fa;
}

.status-success {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28a745;
  color: #ffffff;
}

.status-info {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #17a2b8;
  color: #ffffff;
}

.status-warning {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc107;
  color: #343a40;
}

.status-danger {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eb5757;
  color: #ffffff;
}

.status-dark {
  height: 24px;
  padding: 0px 12px;
  margin: 0px 3px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #343a40;
  color: #ffffff;
}

.status-cash {
  width: 100%;
  height: 120px;
  border-radius: 16px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}


.bg-blue-dark {
  background-color: #0F1A35 !important;
}

.bg-cash-1 {
  background-color: #4E6FFB
}

.bg-primary-light {
  background-color: #448FFF !important;
}

.bg-primary-lights {
  background-color: #ccdbf4 !important;
}

.bg-cash-2 {
  background-color: #68E2FC;
}

.bg-total-2 {
  background-color: #E35A5E;
}

.bg-cash-3 {
  background-color: #FB7175;
}

.btn-button-danger {
  background-color: #EB5757 !important;
}

.bg-danger-light {
  background-color: #F60000 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #e3e3e3 !important;
}

.bg-blue-sky {
  background-color: #e3f8ff;
}

.bg-blue-light {
  background-color: #CCD2E3;
}

.btn-select-member {
  background-color: #EBEFFF !important;
}

.bg-secondary-light {
  background-color: #f5f5f5 !important;
}

.bg-secondary-light-disable {
  background-color: #f7f7f7 !important;
}

.bg-green-dark {
  background-color: #0D9D35 !important;
}

.bg-green-light2 {
  background-color: #00DBA6 !important;
}

.btn-button-green {
  background-color: #2ACA9A !important;
}

.bg-content-2 {
  background-color: #91C83D;
}

.bg-green-light {
  background-color: #67C587 !important;
}

.bg-light-gray {
  background-color: #CDCDCD !important;
}

.bg-grey-type {
  background-color: #b1afaf !important;
}

.bg-secondary-phone {
  background-color: #D4D4D4 !important
}

.bg-dark {
  background-color: #333333 !important
}

.bg-yellow-dark {
  background-color: #E8B951 !important;
}

.bg-yellow-light {
  background-color: #FFE70C !important;
}

.bg-cash-4 {
  background-color: #FECC43;
}

.bg-orange {
  background-color: #E98432 !important;
}

.bg-orange-dark {
  background-color: #BD583D !important;
}


.bg-button-light {
  background-color: #C8D2FF !important;
}

.bg-purple {
  background-color: #8044FF !important;
}


.bd-primary {
  border-color: #4E6FFB !important
}

.bg-yellow-warning {
  background-color: #f9f0dc;
}

.bg-status-stype-one {
  background-color: #A2678A;
}

.bg-status-stype-two {
  background-color: #E19898;
}

.text-yellow-warning {
  color: #7f6434;
}




/* Text color */
.text-title {
  color: #333333 !important
}

.text-header {
  color: #4E6FFB !important
}

.text-disables {
  color: #9e9e9e !important
}

.text-msg {
  color: #BABABA !important
}

.text-disable {
  color: #d9d7d7 !important
}

.text-login {
  color: #999999 !important
}

.text-detail-login {
  color: #CAD5FD !important
}

.text-header-logo {
  color: #0F1A35 !important
}

.text-detail {
  color: #808080 !important
}

.text-detail-name {
  color: #666666 !important
}

.text-green-adjustment {
  color: #67C587 !important
}

.text-yellow-adjustment {
  color: #E8B951 !important
}

/* bordercolor */


.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  z-index: 2000;
}





/* CSS TEXT */
.text-2 {
  font-size: 2px !important;
}

.text-4 {
  font-size: 4px !important;
}

.text-6 {
  font-size: 6px !important;
}

.text-8 {
  font-size: 8px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-15 {
  font-size: 15px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-22 {
  font-size: 22px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-26 {
  font-size: 26px !important;
}

.text-28 {
  font-size: 28px !important;
}

.text-30 {
  font-size: 30px !important;
}

.text-32 {
  font-size: 32px !important;
}

.text-52 {
  font-size: 52px !important;
}



/* CSS MODAL */
.show-modal {
  display: block;
  position: fixed;
  z-index: 1040;
  background-color: #00000050;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.2s;
}

.hide-modal {
  display: none;
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 0.2s;
}

.box-modal {
  position: absolute;
  right: 0px;
  width: 514px;
  max-width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow: scroll;
  background-color: #ffffff;
  animation-name: slide-in;
  animation-duration: 0.2s;
}

.hide-box-modal {
  position: absolute;
  right: -514px;
  width: 514px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #ffffff;
  animation-name: slide-out;
  animation-duration: 0.2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    right: -514px;
  }

  to {
    right: 0px;
  }
}

@keyframes slide-out {
  from {
    right: 0px;
  }

  to {
    right: -514px;
  }
}

.swal2-popup {
  border-radius: 16px !important;
}

.swal2-confirm {
  background-color: #fff !important;
  color: #232f85 !important;
  border: 2px solid #232f85 !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.swal2-cancel {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.swal2-styled {
  border-radius: 10px !important;
}

.modal-filter {
  position: absolute;
  width: 374px;
  z-index: 2000;
}

/* CSS PAGINATION */
ul.pagination li a {
  text-decoration: none;
  color: #333333;
  font-size: 16px;
}

.pagination li {
  width: 30px;
  height: 30px;
  border-radius: 16px;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
  color: #333333;
  font-size: 16px;
}

.pagination .active {
  width: 30px;
  height: 30px;
  border-radius: 16px;
  background-color: #4E6FFB;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .active a {
  color: #ffffff;
}

.pagination .disabled a {
  color: #333333;
}

.pagination li:first-child {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #333333;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.pagination li:last-child {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #333333;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* CSS NAVBAR */
.navbar-component {
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  padding: 0px 32px;
  border-left: 1px solid #c8ced3;
  right: 0;
  z-index: 1039;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

/* -------------------------------------------------------- */
/* CSS WIDTH HEIGHT */
.bodyw-100 {
  width: calc(100% - 260px) !important;
}

.margin-left {
  width: calc(100% - 200px) !important;
}

@media screen and (max-width: 992px) {
  .navbar-component {
    left: 0;
  }

  .bodyw-100 {
    width: 100% !important;
  }

  .margin-left {
    width: 100% !important;
  }

}

.height-vh {
  height: 100vh;
}

.height-vw {
  height: 100vh;
}

.height-main {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.min-vh-100 {
  height: 100vh !important;
}

.overflow-auto {
  overflow: auto;
}



/* ------------------------------------------------------- */



.wpx-16 {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
}

.wpx-20 {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}

.wpx-30 {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

.wpx-32 {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
}

.wpx-38 {
  width: 38px;
  min-width: 38px;
  max-width: 38px;
}

.wpx-40 {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}

.wpx-44 {
  width: 44px;
  min-width: 44px;
  max-width: 44px;
}

.wpx-48 {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.wpx-50 {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

.wpx-60 {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.wpx-70 {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.wpx-80 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

.wpx-90 {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.wpx-100 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.wpx-110 {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}

.wpx-120 {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.wpx-130 {
  width: 130px;
  min-width: 130px;
  max-width: 130px;
}

.wpx-140 {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}

.wpx-150 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.wpx-160 {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}

.wpx-170 {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}

.wpx-180 {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.wpx-190 {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
}

.wpx-200 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.wpx-220 {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
}

.wpx-240 {
  width: 240px;
  min-width: 240px;
  max-width: 240px;
}

.wpx-250 {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.wpx-280 {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}

.wpx-300 {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.wpx-350 {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
}

.wpx-400 {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.wpx-380 {
  width: 380px;
  min-width: 380px;
  max-width: 380px;
}

.wpx-500 {
  width: 500px;
  min-width: 500px;
  max-width: 500px;
}

.hpx-16 {
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.hpx-20 {
  height: 20px;
  min-height: 20px;
  max-height: 20px;
}

.hpx-30 {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
}

.hpx-32 {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.hpx-38 {
  height: 38px;
  min-height: 38px;
  max-height: 38px;
}

.hpx-42 {
  height: 42px;
  min-height: 42px;
  max-height: 42px;
}

.hpx-48 {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

.hpx-50 {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}

.hpx-52 {
  height: 52px;
  min-height: 52px;
  max-height: 52px;
}

.hpx-54 {
  height: 54px;
  min-height: 54px;
  max-height: 54px;
}

.hpx-56 {
  height: 56px;
  min-height: 56px;
  max-height: 57px;
}

.hpx-58 {
  height: 58px;
  min-height: 58px;
  max-height: 58px;
}


.hpx-60 {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
}

.hpx-62 {
  height: 62px;
  min-height: 62px;
  max-height: 62px;
}

.hpx-70 {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
}

.hpx-85 {
  height: 85px;
  min-height: 85px;
  max-height: 85px;
}

.hpx-80 {
  height: 80px;
  min-height: 80px;
  max-height: 80px;
}

.hpx-90 {
  height: 90px;
  min-height: 90px;
  max-height: 90px;
}

.hpx-100 {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}

.hpx-110 {
  height: 110px;
  min-height: 110px;
  max-height: 110px;
}

.hpx-120 {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
}

.hpx-305 {
  height: 305px;
  min-height: 305px;
  max-height: 305px;
}

/* CSS LOADING */
.spinner-container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000030;
  z-index: 10000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/* CSS rounded */
.rounded-16 {
  border-radius: 16px !important;
}

.rounded-top-16 {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.border-grey {
  border-color: #c9c9c9 !important;
}

/* CSS bordercolor */
.custom-select {
  position: relative;
  background-color: #F5F5F5;
  border-radius: 12px;
  width: 100%;
  border-color: transparent
}


.text-line-dashed {
  border-style: dashed !important;
}

.bottom-menu {
  left: 0;
  right: 0;
}

.run-number {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #232f85;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
}

.overflow-scroll {
  overflow: scroll;
}

.none-product {
  height: 120px;
  width: 178px;
  object-fit: cover;
}

.img-page-not-found {
  width: 350px !important;
  min-width: 220px !important;
  max-width: 350px !important;
  object-fit: cover;
}



.number-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}


.number-line-180px {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.number-line-150px {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.clock-icon {
  width: 61px;
  height: 60px;
}

.warning-icon {
  width: 30px;
  height: 30px;
}

.btn-calculate {
  height: 75px !important;
}

.btn-calculate {
  height: 75px !important;
}

.btn-cal-secondary {
  width: 100%;
  height: 62px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  font-size: 20px;
  border-radius: 4px;
}

.btn-cal-primary {
  width: 100%;
  height: 62px;
  background-color: #232F85;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  font-size: 20px;
  border-radius: 4px;
}

.p-01 {
  padding: 2px;
}

.general-cash {
  background-color: #4E6FFB;
  border-radius: 50px;
  padding: 8px 8px;
  color: #ffffff;
}


.bg-signature {
  background-color: #5FB866 !important;
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-signature-cross {
  background-color: #EB5757 !important;
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-search {
  padding: 0px !important;
}

.select-search>div {
  border-radius: 10px !important;
  min-height: 44px !important;
}

.select-search>div:hover {
  border-radius: 10px !important;
  min-height: 44px !important;
}

select {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAA6CAYAAAAa7L/3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMDIxIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NTE1NzkwMjc4NzExMUVEQTJCQjg5NjRBMDRFNjMxQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NTE1NzkwMzc4NzExMUVEQTJCQjg5NjRBMDRFNjMxQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ1MTU3OTAwNzg3MTExRURBMkJCODk2NEEwNEU2MzFDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ1MTU3OTAxNzg3MTExRURBMkJCODk2NEEwNEU2MzFDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+4TxPoAAAAQFJREFUeNrs3DEOgjAUxvE+0s3ZA3gGj+V1PJZbdxev4OyzJg4spcUY+1H/X/IGCAb4UeoLGszdw9aTUvrZvqZAViXOrtJrqBkkjDDAAAMMMAIYYIABBhhgBDDAAAMMMAIYYIABBljfXHPdc51HB/NZfZLj+7OHXLtcp5HBvLJcyz7X5Z9uSW9cV8qt9zwQO1ygElrtR+RHYRsbeYQtnaCrY/X8llyDJoPVu62whjlNCkuhD1ua0+SwVBrXEpocllKnPzW0F6ZyoGEDaKZ0kEEcTepPfjHoRfqBAI93AAMMMMAAI4ABBhhggBHAAAMMMMAAI4ABJgDGGwUYYd/PU4ABAPaCJ2HsYXwgAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 38px 30px;
}

.select {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAA6CAYAAAAa7L/3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMDIxIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NTE1NzkwMjc4NzExMUVEQTJCQjg5NjRBMDRFNjMxQyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NTE1NzkwMzc4NzExMUVEQTJCQjg5NjRBMDRFNjMxQyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ1MTU3OTAwNzg3MTExRURBMkJCODk2NEEwNEU2MzFDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQ1MTU3OTAxNzg3MTExRURBMkJCODk2NEEwNEU2MzFDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+4TxPoAAAAQFJREFUeNrs3DEOgjAUxvE+0s3ZA3gGj+V1PJZbdxev4OyzJg4spcUY+1H/X/IGCAb4UeoLGszdw9aTUvrZvqZAViXOrtJrqBkkjDDAAAMMMAIYYIABBhhgBDDAAAMMMAIYYIABBljfXHPdc51HB/NZfZLj+7OHXLtcp5HBvLJcyz7X5Z9uSW9cV8qt9zwQO1ygElrtR+RHYRsbeYQtnaCrY/X8llyDJoPVu62whjlNCkuhD1ua0+SwVBrXEpocllKnPzW0F6ZyoGEDaKZ0kEEcTepPfjHoRfqBAI93AAMMMMAAI4ABBhhggBHAAAMMMMAAI4ABJgDGGwUYYd/PU4ABAPaCJ2HsYXwgAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 38px 30px;
}


.pro-sidebar-layout {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.pro-sidebar-layout::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

body {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.modal-w {
  width: 70%;
  max-width: 70%;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  min-width: 155px;
  min-height: 155px;
  max-height: 400px;
  max-width: 400px;
}

.chart-EXPENSES {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  min-width: 200px;
  min-height: 200px;
  max-height: 200px;
  max-width: 200px;
}

.chart-container-line {
  position: relative;
  margin: auto;
  height: 100%;
  width: 67%;
  min-width: 400px;
}

.cal {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cal>div {
  height: 60px;
  background-color: #f4f3f3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
}

.cal:hover>div {
  background-color: #e5e5e5;
}

.cal>.primary {
  background-color: #4E6FFB;
  color: var(--white);
}

.cal:hover>.primary {
  background-color: #e5e5e5;
  color: var(--white);
}

.modal-open {
  padding: 0 !important;
}

#button-tooltip>.tooltip-inner {
  background-color: #333333;
  color: #f4f3f3;
  text-align: left;
  font-size: 14px;
  border: rgb(60, 60, 60)
}

.table-sticky {
  position: sticky;
  left: 0px;
  background-color: #CDCDCD !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #4E6FFB;
  color: #FFFFFF;
}

.table .thead-dark-gray th {
  color: #333333;
  background-color: #CDCDCD !important;
  border-color: #CDCDCD !important;
}

.tableFixHead {
  overflow: auto;
  height: 400px
}

.tableFixHead thead th,
.tableFixHead thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #FFFFFF;
  color: #333333;
}

.tableFixHead-primary {
  overflow: auto;
  height: 400px
}

.tableFixHead-primary thead th,
.tableFixHead-primary thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #4E6FFB;
  color: #FFFFFF;
}


/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
  color: #333333;
}

th,
td,
{
padding: 8px 16px;
color: #333333;
}


thead {
  z-index: 5;
}

.table-fixed3 thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 5;
}

.table-fixed3 tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 5;
}

/**/
.table-fixed3 thead th:nth-child(1),
.table-fixed3 tbody tr td:nth-child(1) {
  position: sticky;
  left: 0px;
  z-index: 1;
}

.table-fixed3 thead th:nth-child(2),
.table-fixed3 tbody tr td:nth-child(2) {
  position: sticky;
  left: 70px;
  z-index: 1;
}

.table-fixed3 thead th:nth-child(3),
.table-fixed3 tbody tr td:nth-child(3) {
  position: sticky;
  left: 320px;
  z-index: 1;
}

.table-fixed3 thead th:nth-child(1),
.table-fixed3 thead th:nth-child(2),
.table-fixed3 thead th:nth-child(3) {
  top: 0
}

.table-fixed3 tr th:nth-child(1) {
  left: 0px;
}

.table-fixed3 tr th:nth-child(2) {
  left: 70px;
}

.table-fixed3 tr th:nth-child(3) {
  left: 320px;
}


.table-fixed3 tbody td:nth-child(1) {
  background-color: #ffffff;
}

.table-fixed3 tbody td:nth-child(2) {
  background-color: #ffffff;
}

.table-fixed3 tbody td:nth-child(3) {
  background-color: #ffffff;
}

.table-fixed3 tbody tr:nth-of-type(odd) td:nth-child(1) {
  background-color: #cccccc;
  color: #000000 !important;
}

.table-fixed3 tbody tr:nth-of-type(odd) td:nth-child(2) {
  background-color: #cccccc;
  color: #000000 !important;
}

.table-fixed3 tbody tr:nth-of-type(odd) td:nth-child(3) {
  background-color: #cccccc;
  color: #000000 !important;
}